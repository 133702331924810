import {Component, inject} from '@angular/core'
import {WINDOW} from '../../application/window.provider'

@Component({
  selector: 'kdl-conflict-dialog',
  templateUrl: './conflict-dialog.component.html'
})
export class ConflictDialogComponent {
  protected window = inject(WINDOW)
}
